import Link from "next/link";
import React from "react";
import { useTranslation } from "next-i18next";
import Twitter from "icons/twitter";
import LinkedIn from "icons/linkedin";
import Facebook from "icons/facebook";
import AirHealthWhite from "icons/AirHealthWhite";
import withHover from "components/withHover";
import { shareLink } from "lib/sharing";
import { useShareUrl } from "../hooks/useShareUrl";
import LinkIcon from "icons/link";
import Check from "icons/check";

const YEAR = new Date().getFullYear();

const TwitterWHover = withHover(Twitter);
const LinkedInWHover = withHover(LinkedIn);
const FacebookWHover = withHover(Facebook);
const LinkWHover = withHover(LinkIcon);

function Footer({
  mailingList = false,
  locationName,
  LOCATIONS,
  location,
  sharing = true
}) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  const shareUrl = useShareUrl();

  return (
    <footer role="contentinfo">
      {mailingList && (
        <div className="flex flex-col flex-1 px-10 mx-auto w-full max-w-7xl antialiased">
          <hr className="mx-auto mb-10 w-28 h-2 bg-gray" />
          <h4 className="mb-4 font-sans text-4xl font-bold text-center text-gray-400">
            Sign up for updates on{" "}
            {locationName ? ` in ${locationName.toProperCase()}` : ""} air
            quality
          </h4>
          <p className="mb-12 text-center text-gray-400">
            Enter your email below and receive updates as new local stories,
            resources and insights become available.
          </p>
          <form className="pb-28">
            <div className="grid grid-cols-2">
              <div className="px-2 mb-4">
                <label
                  className="block mb-1 text-sm text-gray-400 uppercase"
                  htmlFor="firstName"
                >
                  First Name*
                </label>
                <input
                  id="firstName"
                  className="w-full border-2 border-gray-300"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>
              <div className="px-2 mb-4">
                <label
                  className="block mb-1 text-sm text-gray-400 uppercase"
                  htmlFor="lastName"
                >
                  Last Name*
                </label>
                <input
                  id="lastName"
                  className="w-full border-2 border-gray-300"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
              <div className="px-2 mb-4">
                <label
                  className="block mb-1 text-sm text-gray-400 uppercase"
                  htmlFor="email"
                >
                  Email*
                </label>
                <input
                  id="email"
                  className="w-full border-2 border-gray-300"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className="px-2 mb-4">
                <label
                  className="block mb-1 text-sm text-gray-400 uppercase"
                  htmlFor="organization"
                >
                  Organization*
                </label>
                <input
                  id="organization"
                  className="w-full border-2 border-gray-300"
                  value={organization}
                  onChange={e => setOrganization(e.target.value)}
                />
              </div>
            </div>
            <button
              className="py-2 px-4 ml-2 text-white bg-gray rounded-md"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      )}
      <div className="w-full text-white bg-gradient-to-r from-community-500 to-public-500">
        {sharing && (
          <ul className="flex justify-center pt-8 pb-4 mx-auto max-w-5xl border-b-2 border-community-300 fill-current">
            <li className="m-4 text-community-300 fill-current">
              <a
                aria-label="Aclima Twitter"
                target="_blank"
                href={shareLink(
                  "tw",
                  shareUrl,
                  LOCATIONS?.[location]?.DEFAULT_SHARE_TEXT?.tw
                )}
                rel="noreferrer"
              >
                <TwitterWHover
                  fill="#FFFFFF"
                  color="#FFFFFF"
                  hovercolor="#94d4d0"
                  stroke-width="1"
                />
              </a>
            </li>
            <li className="m-4 text-community-300 fill-current">
              <a
                aria-label="Aclima Linkedin"
                target="_black"
                href={shareLink(
                  "li",
                  shareUrl,
                  LOCATIONS?.[location]?.DEFAULT_SHARE_TEXT?.li
                )}
              >
                <LinkedInWHover
                  fill="#FFFFFF"
                  color="#FFFFFF"
                  hovercolor="#94d4d0"
                  stroke-width="1"
                />
              </a>
            </li>
            <li className="m-4 text-community-300 fill-current">
              <a
                aria-label="Aclima Facebook"
                target="_black"
                href={shareLink(
                  "fb",
                  shareUrl,
                  LOCATIONS?.[location]?.DEFAULT_SHARE_TEXT?.fb
                )}
              >
                <FacebookWHover
                  fill="#FFFFFF"
                  color="#FFFFFF"
                  hovercolor="#94d4d0"
                  stroke-width="1"
                />
              </a>
            </li>
            <li className="mt-4 ml-4 text-community-300 fill-current">
              <button
                aria-label="Aclima Copy"
                href={shareLink("fb", shareUrl)}
                className="flex items-center"
                onClick={() => {
                  navigator.clipboard.writeText(shareUrl);
                  setCopied(true);
                  setTimeout(() => setCopied(false), 800);
                }}
              >
                {copied ? (
                  <span className="text-community-300">
                    <Check className="inline" /> Link copied!
                  </span>
                ) : (
                  <span className="inline">
                    <LinkWHover
                      fill="none"
                      hovercolor="#94d4d0"
                      fillcolor="none"
                    />
                  </span>
                )}
              </button>
            </li>
          </ul>
        )}
        <div className="flex justify-between pt-6 pb-3 mx-auto w-full max-w-5xl antialiased">
          <Link href="/">
            <a
              className="flex place-items-center ml-2 w-32 h-full rounded-xl focus:outline-none focus:ring-1 focus:ring-brand focus:ring-offset-2 md:block"
              aria-label="Aclima Website"
            >
              <AirHealthWhite size={256} />
            </a>
          </Link>
          <ul>
            <li className="inline mx-4">
              <a
                href="https://www.aclima.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {t("privacy-policy")}
              </a>
            </li>
            <li className="inline mx-4">
              <Link href="/">
                <a
                  href="https://www.aclima.io/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("terms-of-service")}
                </a>
              </Link>
            </li>
            <li className="inline mx-4">
              <a
                href="https://www.aclima.io/end-user-license"
                target="_blank"
                rel="noreferrer"
              >
                {t("license")}
              </a>
            </li>
          </ul>
        </div>

        <div className="pb-6 pl-2 mx-auto w-full max-w-5xl antialiased text-white">
          <p className="text-xs">© {YEAR} Aclima, Inc.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
