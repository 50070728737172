// lib/sanity.server.js
import { createClient } from "next-sanity";
import { config } from "./sanity.config";

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient({
  ...config,
  // need to be authenticated for i18n https://github.com/LiamMartens/sanity-plugin-intl-input/blob/develop/docs/important-configuration.md#id-structure
  token: process.env.SANITY_API_TOKEN
});

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN
});

// Helper function for easily switching between normal client and preview client
export const getClient = usePreview =>
  usePreview ? previewClient : sanityClient;
