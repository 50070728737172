import qs from "qs";
import { point } from "@turf/turf";

export const filterByProperty = (defaultPollutant, data, property) => {
  return (pollutant, values) => {
    if (pollutant === defaultPollutant) {
      return data.features.filter(f => {
        return (
          f.properties[pollutant] &&
          values.includes(f.properties[pollutant]?.toString() ?? "0") &&
          (property ? f.properties[property] : true)
        );
      });
    }

    return data.features.filter(f => {
      return (
        f.properties[pollutant] &&
        values.includes(f.properties[defaultPollutant]?.toString() ?? "0") &&
        (property ? f.properties[property] : true)
      );
    });
  };
};

export const filterByRoadType = (defaultPollutant, data, roadTypes) => {
  return (pollutant, values) => {
    if (pollutant === defaultPollutant) {
      return data.features.filter(f => {
        return (
          f.properties[pollutant] &&
          values.includes(f.properties[pollutant]?.toString() ?? "0") &&
          roadTypes.some(type => f.properties.road_type === type)
        );
      });
    }

    return data.features.filter(f => {
      return (
        f.properties[pollutant] &&
        roadTypes.some(type => f.properties.road_type === type) &&
        values.includes(f.properties[defaultPollutant]?.toString() ?? "0")
      );
    });
  };
};

export const getDisplayValueByPollutantConstants =
  pollutantConstants => (value, pollutant) => {
    const { detectionLimit } = pollutantConstants[pollutant];
    if (!value) {
      return null;
    }
    if (value < detectionLimit) {
      return `< ${detectionLimit}`;
    }
    return pollutant === "co"
      ? value.toFixed(2)
      : pollutant === "co2"
      ? value.toFixed(0)
      : value.toFixed(1);
  };

export const getIsUnderDetectableLimitByPollutantConstants =
  pollutantConstants => (value, pollutant) => {
    const { detectionLimit } = pollutantConstants[pollutant];
    return value < detectionLimit;
  };

export const getShortAddressFromAddressComponents = address_components => {
  if (!address_components?.length) {
    return "Unknown Address";
  }
  const hasAddress = address_components?.[0]?.types?.includes("street_number");
  const name = address_components?.[0]?.long_name;
  const streetNumber = address_components.find(el =>
    el.types.includes("street_number")
  )?.short_name;
  const streetName = address_components.find(el =>
    el.types.includes("route")
  )?.short_name;
  const city = address_components.find(el =>
    el.types.includes("locality")
  )?.long_name;
  const shortAddress = hasAddress
    ? `${streetNumber} ${streetName}, ${city}`
    : name;
  return shortAddress;
};

export async function fetcher(...args) {
  return fetch(...args).then(res => res.json());
}

export async function getPlaceByLatLng(lat, lng) {
  const latlng = `${lat},${lng}`;
  return await fetcher(
    `https://maps.googleapis.com/maps/api/geocode/json?${qs.stringify({
      latlng
    })}&key=AIzaSyCAu-h8qBh0p6pTCnYpJcfVgZ-VJP6h0ik`
  ).then(data => {
    const { results } = data;
    if (results.length > 0) {
      const { formatted_address, geometry, place_id, address_components } =
        results[0];
      const { location } = geometry;
      const { lat, lng } = location;
      const place = point([lng, lat]);
      place.properties = {
        address: formatted_address,
        shortAddress: getShortAddressFromAddressComponents(address_components),
        placeId: place_id
      };
      return place;
    }
  });
}

export function debounce(fn, ms) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, ms);
  };
}

export const getBeforeId = (mapStyle, defaultId = "") =>
  mapStyle === "light" ? "admin-1-boundary-bg" : defaultId;
