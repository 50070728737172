/* eslint-disable react/display-name */
import { Children } from "react";

import ReactPortableText from "react-portable-text";

/**
 *  We only want to include strings when building anchors.
 *  Otherwise, it would say [object Object] in the anchor.
 */
const getLabelFromChildren = children => {
  let label = "";

  Children.map(children, child => {
    if (typeof child === "string") {
      label += child;
    }
  });

  return label;
};

export function PortableTextAttribution({ content }) {
  if (!content) {
    return null;
  }
  return (
    <>
      <div className="attribution-grid-child">
        <ReactPortableText
          content={content}
          serializers={{
            subscript: ({ children }) => <sub>{children}</sub>,
            superscript: ({ children }) => <sup>{children}</sup>,
            h1: ({ children }) => (
              <div className="col-span-1 col-start-1">{children}</div>
            ),
            h2: ({ children }) => (
              <div className="col-span-2 col-start-2 font-bold text-gray-bluebrand">
                {children}
              </div>
            )
          }}
        />
      </div>
    </>
  );
}

export const PortableTextBulletPoints = ({ content }) => {
  if (!content) return null;
  const summarySerializers = {
    ol: ({ children }) => <ol className="pl-4 list-decimal">{children}</ol>,
    li: ({ children }) => <li className="py-1">{children}</li>,
    normal: ({ children }) => <p className="pb-1">{children}</p>,
    link: props => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-community-600 underline cursor-pointer"
        {...props}
      >
        {props.children}
      </a>
    )
  };
  return (
    <ReactPortableText content={content} serializers={summarySerializers} />
  );
};

export const PortableTextReadMeModal = ({ content }) => {
  if (!content) return null;
  const summarySerializers = {
    li: ({ children }) => <li className="py-1 ml-6 list-disc">{children}</li>,
    h4: ({ children }) => <h4 className="pt-1 pb-6 text-lg">{children}</h4>,
    subscript: ({ children }) => <sub>{children}</sub>,
    superscript: ({ children }) => <sup>{children}</sup>
  };
  return (
    <ReactPortableText content={content} serializers={summarySerializers} />
  );
};

export default function PortableText({
  content,
  location,
  LOCATIONS,
  showBold = true,
  anchorBold = false
}) {
  if (!content) {
    return null;
  }
  const CHARTS_AND_TABLES = LOCATIONS?.[location]?.CHARTS_AND_TABLES;
  return (
    <ReactPortableText
      content={content}
      serializers={{
        subscript: ({ children }) => <sub>{children}</sub>,
        superscript: ({ children }) => <sup>{children}</sup>,
        strong: ({ children }) =>
          showBold ? (
            <strong id={anchorBold ? getLabelFromChildren(children) : null}>
              {children}
            </strong>
          ) : (
            children
          ),
        link: props => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-community-600 underline cursor-pointer"
            {...props}
          >
            {props.children}
          </a>
        ),
        component: ({ id }) => {
          const Component = CHARTS_AND_TABLES.find(
            el => el.id === id
          )?.Component;
          const props = CHARTS_AND_TABLES.find(el => el.id === id)?.props;
          return Component ? <Component {...props} /> : <></>;
        }
      }}
    />
  );
}
