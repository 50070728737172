// https://github.com/Alex61NN5/easy-social-share-links/blob/master/index.js
export const shareLink = (provider, link, text = null) => {
  switch (provider) {
    case "fb":
      return `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    case "tw":
      return `https://twitter.com/intent/tweet?url=${link}${
        text ? `&text=${text}` : ""
      }`;
    case "li":
      return `https://www.linkedin.com/sharing/share-offsite/?url=${link}${
        text ? `/&summary=${text}` : ""
      }`;
    default:
      return "#";
  }
};
