import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";

const PROD_DOMAIN = "air.health";

const IS_PROD_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

const prodOnlyPlugins = [
  mixpanelPlugin({
    token: "5926bf42e977ddc9b1d1e8767d991712",
    options: { debug: !IS_PROD_ENV }
  })
];

// remove IS_PROD_ENV check for logging in development or previews
const plugins = [...(IS_PROD_ENV ? prodOnlyPlugins : [])];

const analytics = Analytics({
  app: PROD_DOMAIN,
  plugins
});

export default analytics;
