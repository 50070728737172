import * as React from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

export function LocaleSwitcher({ style = "dark", size = "medium" }) {
  const LOCALE_LABELS = {
    "en-US": "En",
    es: "Es"
  };

  const router = useRouter();

  return (
    <div
      className={clsx("block w-max", {
        "text-white": style === "light",
        "text-sm": size === "small",
        "h-8": size !== "small"
      })}
    >
      {router.locales.map((locale, index) => {
        const isCurrentLocale = locale === router.locale;
        const isLastLocale = index === router.locales.length - 1;
        if (isCurrentLocale) {
          return (
            <span key={locale}>
              <span className="font-bold">{LOCALE_LABELS?.[locale]}</span>
              <span>{isLastLocale ? "" : " / "}</span>
            </span>
          );
        }
        if (!isCurrentLocale) {
          return (
            <React.Fragment key={locale}>
              <button
                className={clsx("underline underline-offset-2 cursor-pointer", {
                  "text-white": style === "light",
                  "text-community-600": style === "dark"
                })}
                onClick={() =>
                  router.push(router.asPath, undefined, {
                    locale
                  })
                }
              >
                {LOCALE_LABELS?.[locale]}
              </button>
              <span>{isLastLocale ? "" : " / "}</span>
            </React.Fragment>
          );
        }
      })}
    </div>
  );
}
