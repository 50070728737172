import * as Sentry from "@sentry/nextjs";
import { piecewise, interpolateRgb } from "d3-interpolate";
import { interpolateYlOrRd, interpolateOranges } from "d3-scale-chromatic";
import * as d3scale from "d3-scale";
import * as d3color from "d3-color";
import {
  DIESEL_INDICATOR,
  METHANE_INDICATOR,
  TRAFFIC_INDICATOR,
  PM_INDICATOR
} from "lib";

export const HIGHLIGHT_RGBA = [247, 204, 71, 255];

// https://stackoverflow.com/a/21648508
export function hexToRgbA(hex, a = 255) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255, a];
  }
  Sentry.captureException(new Error("Bad Hex"));
}

export const convertToRGBA = (colorString, opacity = 1) => {
  const d = d3color.color(colorString);
  return [d.r, d.g, d.b, opacity * 255];
};

export const convertToCssRGBA = (colorString, opacity = 1) => {
  const d = d3color.color(colorString);
  return [d.r, d.g, d.b, opacity];
};

export const getScaleColorForIndex = (min, max) => value => {
  const checkIfNull = value ? value : 0;
  const opacity = 0.7;
  if (!value) {
    return [255, 245, 235, opacity * 255];
  }
  const scale = d3scale
    .scaleLinear()
    .domain([min, max])
    .range([0, 1])
    .clamp(true);
  return convertToRGBA(interpolateOranges(scale(checkIfNull)), opacity);
};

export function RGBAToHex(rgbaArray) {
  var [r, g, b, a] = rgbaArray;
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = b.toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;
  if (a.length == 1) a = "0" + a;

  return "#" + r + g + b;
}

// bg-[#5D69B1] bg-[#99C945] bg-[#DAA51B] bg-[#ED645A]
export const hexColors = {
  blue: "#5D69B1",
  green: "#191919", // TODO: this is brand blue, rename
  yellow: "#DAA51B",
  red: "#ED645A",
  gray: "#818C96",
  text: "#153D57",
  black: "#000000"
};

export const rgbaColors = Object.fromEntries([
  ...Object.entries(hexColors).map(([k, v]) => [k, hexToRgbA(v, 150)]),
  ["transparent", [255, 255, 255, 0.7]]
]);

export const STUDY_AREA_COLOR = [58, 165, 157, 188];

const pmScale = d3scale
  .scaleLinear()
  .domain([4.4, 30])
  .range([0, 1])
  .clamp(true);

// border-[#9fe8dc] bg-[#97c696] bg-[#a39d59] bg-[#ae723c] bg-[#ae483f] bg-[#9c1a54]
export const lchColorScheme = [
  "#9fe8dc",
  "#97c696",
  "#a39d59",
  "#ae723c",
  "#ae483f",
  "#9c1a54"
];

export const lchColorScale = piecewise(interpolateRgb, lchColorScheme);

export const pollutantColorScheme = {
  pm25: ["#fae1d5", "#971216"],
  "pm_2.5": ["#fae1d5", "#971216"],
  no2: ["#ece1c5", "#712600"],
  bc: ["#B6BCD5", "#020054"],
  blackcarbon: ["#B6BCD5", "#020054"],
  ch4: ["#e1c8e7", "#3d1389"],
  co: ["#f8ceea", "#590168"],
  co2: ["#ffd8e5", "#8b1d64"],
  o3: ["#d1ecf5", "#004d45"]
};

export const indicatorLegendColors = {
  diesel: {
    1: { label: "High Diesel Pollution Impact", color: "#0CA7A1" },
    2: { label: "Medium Diesel Pollution Impact", color: "#83D5D0" }
  },
  trafficIndicator: {
    1: { label: "High Traffic Pollution Impact", color: "#B333C1" },
    2: { label: "Medium Traffic Pollution Impact", color: "#D999E0" }
  },
  pmIndicator: {
    1: { label: "High PM Impact", color: "#962224" },
    2: { label: "Medium PM Impact", color: "#CA9091" }
  },
  methaneIndicator: {
    1: { label: "Methane Indicator", color: "#713BCE" }
  }
};

export const generatePollutantColorScale = colorScale =>
  Object.fromEntries(
    Object.entries(colorScale).map(([k, v]) => [
      k,
      piecewise(interpolateRgb, v)
    ])
  );

export const pollutantColorScale =
  generatePollutantColorScale(pollutantColorScheme);

export const plasmaColorScheme = [
  "#0d0887",
  "#6a00a8",
  "#b12a90",
  "#e16462",
  "#fca636",
  "#f0f921"
];
const plasmaColorScale = piecewise(interpolateRgb, plasmaColorScheme);

export const getScaleColor = mean => {
  return convertToRGBA(lchColorScale(pmScale(mean)));
};

export const getScaleColorForPollutant = ({
  pollutant,
  pollutants,
  value,
  type,
  customScale,
  discrete = false
}) => {
  const scale = customScale
    ? customScale
    : discrete
    ? d3scale
        .scaleQuantize()
        .domain([pollutants[pollutant].min, pollutants[pollutant].max])
        .range([0, 1])
    : d3scale
        .scaleLinear()
        .domain([pollutants[pollutant].min, pollutants[pollutant].max])
        .range([0, 1])
        .clamp(true);
  const checkIfNull = value ? value : 0;
  if (type === "linear") {
    return convertToRGBA(lchColorScale(scale(checkIfNull)));
  }
  if (type === "plasma") {
    return convertToRGBA(plasmaColorScale(scale(checkIfNull)));
  }
  return convertToRGBA(interpolateYlOrRd(scale(checkIfNull)));
};

export const getMaxMultiScaleColor = mean => {
  return convertToRGBA(interpolateYlOrRd(pmScale(mean)));
};

export const dieselIndicatorScale = d3scale
  .scaleQuantize()
  .domain([0, 3])
  .range([
    [162, 212, 215, 0],
    [12, 167, 161, 255],
    [131, 213, 208, 255],
    [244, 222, 62, 255]
  ]);

export const trafficIndicatorScale = d3scale
  .scaleQuantize()
  .domain([0, 3])
  .range([
    [162, 212, 215, 0],
    [179, 51, 193, 255],
    [217, 153, 224, 255],
    [244, 222, 62, 255]
  ]);

export const methaneIndicatorScale = [113, 59, 206, 255];

export const pmIndicatorScale = d3scale
  .scaleQuantize()
  .domain([0, 3])
  .range([
    [162, 212, 215, 0],
    [150, 34, 36, 255],
    [202, 144, 145, 255],
    [244, 222, 62, 255]
  ]);

export const getSegmentColor =
  (pollutants, colorScaleOverride = null, opacity = 1) =>
  (pollutant, value) => {
    const scale = d3scale
      .scaleLinear()
      .domain([pollutants[pollutant].min, pollutants[pollutant].max])
      .range([0, 1])
      .clamp(true);

    switch (pollutant) {
      case DIESEL_INDICATOR:
        return dieselIndicatorScale(value);
      case METHANE_INDICATOR:
        return methaneIndicatorScale;
      case TRAFFIC_INDICATOR:
        return trafficIndicatorScale(value);
      case PM_INDICATOR:
        return pmIndicatorScale(value);
      default:
        return colorScaleOverride
          ? convertToRGBA(colorScaleOverride[pollutant](scale(value)), opacity)
          : convertToRGBA(
              pollutantColorScale[pollutant](scale(value)),
              opacity
            );
    }
  };

// https://stackoverflow.com/a/41491220 adapted to take rgba colors
export function isContrastColorDark(rgbaColor) {
  const [r, g, b, a] = rgbaColor;
  const brightness = r * 0.299 + g * 0.587 + b * 0.114 + (255 - a);

  return brightness > 186;
}
