import * as Sentry from "@sentry/nextjs";

const b64encode = data => {
  return Buffer.from(data, "utf-8").toString("base64");
};

export const b64Decode = base64 => {
  return Buffer.from(base64, "base64").toString("utf-8");
};

export function decodeUrlSafeBase64(value) {
  const base64Value = value.replace(/-/g, "/").replace(/_/g, "+");
  return b64Decode(base64Value);
}

export function encodeUrlSafeBase64(value) {
  const base64Value = b64encode(value);
  return base64Value.replace(/\//g, "-").replace(/\+/g, "_");
}

export const dataToUrlString = state => {
  try {
    return encodeUrlSafeBase64(JSON.stringify(state));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export function parseUrlState(stateString) {
  if (!stateString) {
    return undefined;
  }
  try {
    return JSON.parse(decodeUrlSafeBase64(String(stateString)));
  } catch (error) {
    Sentry.captureException(error);
  }
}
