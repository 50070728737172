import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { dataToUrlString } from "lib/query";
import qs from "qs";

// wait till component is mounted to access window.location;
export const useShareUrl = (props = {}) => {
  const router = useRouter();
  const [shareUrl, setShareUrl] = useState("");
  useEffect(() => {
    const host = window?.location?.host;
    const baseUrl = host ? `https://${host}` : "/";
    const slug = router.query?.slug;
    let shareUrl = !slug
      ? `${baseUrl}${router.basePath}`
      : `${baseUrl}${router.basePath}/${slug}`;
    let queryParams = {};
    if ("state" in props) {
      const encodedState = dataToUrlString(props.state);
      queryParams = encodedState;
    }
    if ("hexId" in props && props.hexId) {
      queryParams["polygonId"] = props.hexId;
    }
    if ("placeId" in props && props.placeId) {
      queryParams["placeId"] = props.placeId;
    }
    if ("social" in props && props.social) {
      queryParams["social"] = props.social;
    }
    if ("contract" in props && props.contract) {
      queryParams["contract"] = props.contract;
    }

    const urlWithQuery = `${shareUrl}?${qs.stringify(queryParams)}`;

    setShareUrl(
      props?.social ? encodeURIComponent(urlWithQuery) : urlWithQuery
    );
  }, [router.basePath, router.query, props]);

  return shareUrl;
};
